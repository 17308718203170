// consumer_insurance_saveChoosePackage 保存订单所选商旅保障包
// https://www.apifox.cn/apidoc/shared-c91c112c-ac85-4f14-8607-3d32d61d8a80/api-21934424
const __request = require(`./__request/__request_contentType_json`);
const consumer_insurance_saveChoosePackage = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/insurance/saveChoosePackage',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_insurance_saveChoosePackage;
